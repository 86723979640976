<template>
  <div class="main-container">
    <video src="../assets/bg-space.mp4" loop autoplay muted playsinline></video>
    <div class="center-box">
      <img src="../assets/red_sus.png" class="img-fluid" @click="goNext">
      <h1>Sussy Karaoke</h1>
    </div>
  </div>
</template>
<style scoped>
@keyframes spin-in {
  from {
    transform: rotate(560deg) translate(200%, 200%) scale(10);
  }
  to {
    transform: rotate(0deg) translate(0%, 0%) scale(1);
  }
}
@keyframes slide-up {
  from {
    transform: translateY(2000px);
  }
  to {
    transform: translateY(0%);
  }
}
.main-container{
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
video{
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: fill;
  top: 0;
  left: 0;
}
.center-box{
  position: relative;
  z-index: 10;
  color: #fff;
  text-align: center;
}
.center-box > img{
  animation: spin-in 1566ms ease-in-out 100ms 1 normal forwards;
  transform: rotate(560deg) translate(200%, 200%) scale(10);
  transform-origin: center center;
}
.center-box > h1{
  font-size: 64pt;
  font-style: italic;
  animation: slide-up 1566ms ease 266ms 1 normal forwards;
  transform: translateY(2000px);
  transform-origin: center center;
}
.goNext{
  position: fixed;
  background-color: rgba(0,0,0,0.8);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
</style>
<script>
import { v4 as uuidv4 } from 'uuid';
export default {
  data: () => ({
    showButton: false,
  }),
  methods:{
    goNext(){
      const roomId = uuidv4();
      this.$router.push({ name: 'screen', params: { roomId } });
    },
  },
  mounted(){
    this.$nextTick(() => {
      setTimeout(() => this.showButton = true, 3000);
    });
  },
}
</script>
